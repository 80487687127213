import {
  get, download, post,
} from './helpers'

export function getJenisKelamin() {
  return get('/master/jenis-kelamin')
}

export function getDisabilitas() {
  return get('/master/jenis-disabilitas')
}

export function getJenisPendidikan() {
  return get('/master/jenis-pendidikan')
}

export function getStatusPekerjaan() {
  return get('/master/status-pekerjaan')
}

export function getStatusPernikahan() {
  return get('/master/status-pernikahan')
}

export function getStatusPermohonan() {
  return get('/master/status-permohonan')
}

export function getStatusAsset() {
  return get('/master/status-asset')
}

export function getJenisAsset() {
  return get('/master/jenis-asset')
}

export function getTipeTag() {
  return get('/master/tipe-tag')
}

export function getTag(tipe) {
  return get(`/master/tag/${tipe}`)
}

export function getJenisKegiatan() {
  return get('/master/jenis-kegiatan')
}

export function getKondisiAsset() {
  return get('/master/kondisi-asset')
}

export function getKanwil() {
  return get('/master/kanwil')
}

export function getKonsultan() {
  return get('/user/konsultan')
}

export function getPradesain() {
  return get('/pradesain/data')
}

export function getRole() {
  return get('/master/role')
}

export function getUPT(kanwilId) {
  return get(`/master/upt/${kanwilId}`)
}

export function getNegara() {
  return get('/master/negara')
}

export function getKewarganegaraan() {
  return get('/master/kewarganegaraan')
}

export function getAgama() {
  return get('/master/agama')
}

export function getStrukturOrganisasi() {
  return get('/master/struktur-organisasi')
}

export function getPropinsi() {
  return get('/master/wilayah-administrasi/propinsi')
}

export function getKabKota(idPropinsi) {
  return get(`/master/wilayah-administrasi/propinsi/${idPropinsi}/kabkota`)
}

export function getAllJenisDokumen(tipe) {
  return get(`/master/jenis-dokumen/tipe/${tipe}`)
}

export function getJenisDokumen(jenisDokumenId) {
  return get(`/master/jenis-dokumen/${jenisDokumenId}`)
}

export function getJenisKartuIdentitas() {
  return get('/master/jenis-kartu-identitas')
}

export function getJenisPermohonan() {
  return get('/master/jenis-permohonan')
}

export function getAlasanPengakhiran() {
  return get('/master/alasan-pengakhiran')
}

export function getFaqKategori() {
  return get('/master/faq-kategori')
}

export function getFaqAll() {
  return get('/master/faq-all')
}

export function getJenisProgram() {
  return get('/master/jenis-program')
}

export function getPertanyaan(kategoriId) {
  return get(`/master/pertanyaan/${kategoriId}`)
}

export function getJenisKeterampilan() {
  return get('/master/jenis-keterampilan')
}

export function getStatusKeterampilan() {
  return get('/master/status-keterampilan')
}

export function getKategoriKeterampilan() {
  return get('/master/kategori-keterampilan')
}

export function exportExcel($data) {
  return download('daftar-user-project/exportExcel', $data)
}

export function exportLaporan($data) {
  return download('laporan-kegiatan/exportExcel', $data)
}

export function deleteJenisKeterampilan($data) {
  return post('/kelolaketerampilan/delete', $data)
}

export function activationUser(data) {
  return post('/pengguna/activation', data)
}
